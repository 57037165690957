<template>
    <el-dialog append-to-body :visible.sync="insertVisible" :show-close="false"
               @close="$emit('update:insertVisible', false)"
               width="712px">
        <div class="dialog-wrap">
            <div class="dialog-title">
                <span>新增供应商</span>
            </div>
            <div class="dialog-main">
                <el-form class="dialog-form" :model="supplierInfo" ref="supplierForm" :rules="rules">
                    <el-row class="row-first">
                        <el-col :span="8">
                            <el-form-item prop="uniqueCode" label="供应商编码">
                                <el-input v-model="supplierInfo.uniqueCode" placeholder="请输入供应商编码"
                                          maxlength="32"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="16">
                            <el-form-item prop="fullName" label="供应商名称">
                                <el-input class="supplier-full-name" v-model="supplierInfo.fullName" maxlength="256"
                                          placeholder="请输入供应商名称"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="row-first">
                        <el-col :span="8">
                            <el-form-item prop="shortName" label="供应商简称">
                                <el-input v-model="supplierInfo.shortName" placeholder="请输入供应商简称"
                                          maxlength="128"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="cooperateSellerStatus" label="供应商状态">
                                <el-select v-model="supplierInfo.cooperateSellerStatus">
                                    <el-option v-for="item in cooperateSellerStatuses"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="purType" label="供应商分类">
                                <el-select v-model="supplierInfo.purType">
                                    <el-option v-for="item in CooperateSellerPurTypes"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="row-first">
                        <el-col :span="8">
                            <el-form-item prop="taxLevel" label="纳税级别">
                                <el-select v-model="supplierInfo.taxLevel">
                                    <el-option v-for="item in CompanyTaxLevels"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="isInner" label="是否内部供应商">
                                <el-select v-model="supplierInfo.isInner">
                                    <el-option label="是" value="true"></el-option>
                                    <el-option label="否" value="false"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="enterTime" label="入驻时间">
                                <el-date-picker
                                        v-model="supplierInfo.enterTime"
                                        type="date"
                                        placeholder="请选择入驻时间"
                                        :picker-options="dateOption"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="row-first">
                        <el-col :span="12">
                            <el-form-item prop="address" class="invoice-textarea" label="地址">
                                <el-input type="textarea" class="invoice-input" v-model="supplierInfo.address"
                                          maxlength="200"
                                          placeholder="请输入地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="remark" class="invoice-textarea" label="备注">
                                <el-input type="textarea" class="invoice-input" v-model="supplierInfo.remark"
                                          maxlength="200"
                                          placeholder="请输入200字以内的备注"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="line"></div>
                    <div class="invoice-title">开票信息</div>
                    <el-row class="row-first">
                        <el-col :span="12">
                            <el-form-item prop="name" label="名称">
                                <el-input class="invoice-input" v-model="supplierInfo.name" maxlength="128"
                                          placeholder="请输入开票名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="taxNo" label="纳税人识别号">
                                <el-input class="invoice-input" v-model="supplierInfo.taxNo" maxlength="20"
                                          placeholder="请输入纳税人识别号"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="row-first">
                        <el-col :span="12">
                            <el-form-item prop="addressTel" class="invoice-textarea" label="地址、电话">
                                <el-input type="textarea" class="invoice-input" v-model="supplierInfo.addressTel"
                                          maxlength="200"
                                          placeholder="请输入地址、电话"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="bankAccount" class="invoice-textarea" label="开户行及账号">
                                <el-input type="textarea" class="invoice-input" v-model="supplierInfo.bankAccount"
                                          maxlength="200"
                                          placeholder="请输入开户行及账号"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="dialog-footer" slot="footer">
                <el-button @click="$emit('update:insertVisible', false)">取消</el-button>
                <el-button type="primary" @click="handleInsertSupplier">
                    保存
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import {insertSupplier} from "@/service/sellerManage";
    import {dateOption} from "@/util/mixins";

    export default {
        name: "SupplierInsert",
        props: ["insertVisible"],
        mixins:[dateOption],
        data() {
            return {
                cooperateSellerStatuses: this.$store.state.enums.CooperateSellerStatus,
                CooperateSellerPurTypes: this.$store.state.enums.CooperateSellerPurType,
                CompanyTaxLevels: this.$store.state.enums.CompanyTaxLevel,
                supplierInfo: {
                    uniqueCode: "",
                    fullName: "",
                    shortName: "",
                    cooperateSellerStatus: "",
                    purType: "",
                    taxLevel:"",
                    isInner:"",
                    enterTime:"",
                    address:"",
                    remark:"",
                    name:"",
                    taxNo:"",
                    addressTel: "",
                    bankAccount: "",
                },
                rules: {
                    uniqueCode: [{required: true, message: "请输入供应商编码!", trigger: 'blur'}],
                    fullName: [{required: true, message: "请输入供应商名称!", trigger: 'blur'}],
                    name: [{required: true, message: "请输入供应商开票名称!", trigger: 'blur'}]
                }
            }
        },
        methods: {
            async handleInsertSupplier() {
                this.$refs.supplierForm.validate(valid => {
                    if (!valid) return;
                })
                const {success} = await insertSupplier(this.supplierInfo);
                if (success) {
                    this.toast("供应商新增成功", "success", () => {
                        this.$emit("update:insertVisible", false);
                        this.$emit("refresh");
                    });
                }
            },
            reset(){
                console.log("重置表单")
                this.$refs.supplierForm.resetFields();
            }
        }
    }
</script>

<style scoped lang="scss">
    .dialog-wrap {
        .dialog-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 24px;
            font-size: 18px;
            color: #333333;
            margin-top: -40px;
        }

        .dialog-main {
            padding: 24px;

            .line {
                display: block;
                margin-top: 40px;
                border-bottom: 1px solid #ccc;
            }

            .invoice-title {
                margin: 32px 0 24px;
                color: #333333;
                font-size: 16px;
                text-align: left;
            }

            .supplier-full-name {
                width: 420px;
            }

            .invoice-input {
                width: 316px;
            }

            .invoice-textarea {
                height: 120px;
            }

        }

        .dialog-footer {
            height: 32px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 0px 24px 24px 24px;
        }
    }

    ::v-deep .el-form-item__content {
        display: table-caption;
    }

    ::v-deep .el-textarea__inner {
        height: 96px;
    }

    ::v-deep .el-form-item__error {
        top: 100%;
        padding-top: 2px;
    }
</style>
